async function LoadReact () {
    const { default: React } = (await import(
        /* webpackChunkName: 'landing-api/elements-core' */
        /* prefetch: false */
        "react"
    ));
    const { render, unmountComponentAtNode } = (await import(
        /* webpackChunkName: 'landing-api/elements-core' */
        /* prefetch: false */
        "react-dom"
    ));
    return { React, render, unmountComponentAtNode };
}

export type $ExtractPropsType<T extends React.ComponentType<any>> = T extends React.ComponentType<infer P> ? P : never;

export function registerAsyncElement<T extends React.ComponentType<any>> (tag: string, factory: () => Promise<{ default: T }>) {
    class EmCustomElement extends HTMLElement {
        async connectedCallback () {
            const { React, render } = await LoadReact();
            const props = Object.fromEntries(Object.values(this.attributes).map(attribute => [attribute.name, attribute.value] as const)) as $ExtractPropsType<T>;
            const Component = React.lazy(factory);
            render((
                <div className="em-component-root">
                    <React.Suspense fallback={<>Chargement en cours</>}>
                        <Component {...props} />
                    </React.Suspense>
                </div>
            ), this);
        }

        async disconnectedCallback () {
            const { unmountComponentAtNode } = await LoadReact();
            unmountComponentAtNode(this);
        }
    }

    customElements.define(tag, EmCustomElement);

    console.log(`Registered CustomElement: ${tag}`);

    return EmCustomElement;
}